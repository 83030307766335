<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  created () {
    // this.$http.get('/article/info/2')
    //   .then(function (response) {
    //     console.log('首页数据', response)
    //   })
    //   .catch(function (error) {
    //     console.log(error)
    //   })
  }
}
</script>
