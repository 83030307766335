import axios from 'axios'
const http = {}
const baseUrl = process.env.NODE_ENV === 'production' ? '/api' : '/api'
const instance = axios.create({
  // baseURL: 'https://some-domain.com/api/',
  baseURL: baseUrl,
  timeout: 1000
})

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

// 响应拦截器即异常处理
instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error)
})

http.get = function (url, options) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, options)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data.data)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}
http.post = function (url, options) {
  return new Promise((resolve, reject) => {
    instance
      .post(url, options)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.data)
        }
      }).catch((error) => {
        reject(error)
      })
  })
}

export default http
