<template>
  <div>
    <div class="article" v-if="article.title">
      <div class="title">
        {{article.title}}
      </div>
      <div class="infor">
        <div class="name">{{article.author_name}}</div>
        <div class="time" v-if="queryHs != 1">阅读量：{{article.post_hits}}</div>
      </div>
      <div class="content">
        <div v-html="article.content"></div>
      </div>
      <div class="download" v-if="article.is_down_app == 1 && queryHd != 1">
        <a href="http://download.jinrifuyin.yimaneili.cc">下载今日福音APP，每日灵修听道</a>
      </div>
    </div>
    <div class="loading" v-else>
      正在加载中...
    </div>
  </div>
</template>

<script>
import { formatDate } from '../util/date.js'
export default {
  name: 'Article',
  data () {
    return {
      article: {},
      showState: false,
      queryHd: '',
      queryHs: ''
    }
  },
  created () {
    var that = this
    const Id = this.$route.params.id
    this.queryHd = this.$route.query.hd
    this.queryHs = this.$route.query.hs
    that.$http.get('/article/info/' + Id)
      .then(function (res) {
        that.article = res
      })
  },
  filters: {
    formatDate (time) {
      const date = new Date(time * 1000)
      return formatDate(date, 'yyyy-MM-dd')
    }
  },
  metaInfo () {
    return {
      title: this.article.title
    }
  }
}
</script>

<style lang="stylus">
.article
  padding 15px 20px 60px 20px
  .title
    font-size 22px
    line-height 30px
    color #202020
    font-weight bold
    padding-bottom 15px
  .infor
    padding-bottom 10px
    div
      display inline-block
    .name
      font-size 14px
      color #666666
      padding-right 20px
    .time
      font-size 12px
      color #999999
    .font-setting
      padding-left 20px
      position relative
    .size
      position absolute
      padding 6px 12px
      background #ccc
      border 1px solid #666666
      top 23px
      left -10px
      div
        display table-cell
        padding 0 10px
        font-size 16px
        text-decoration underline
  .content
    font-size 20px
    line-height 29px
    letter-spacing 2px
    img
      max-width 100%
      margin auto
    audio
      width 100%
    p
      // margin-bottom 25px
    iframe
      width 100%
      height 250px
    .ql-align-center
      text-align center
    .ql-size-large
      font-size 24px
    .ql-size-small
      font-size 16px
  .download
    position fixed
    width 90%
    bottom 0px
    background-color #C53B48
    text-align center
    height 40px
    line-height 40px
    border-radius 20px
    a
      font-size 16px
      line-height 22px
      color #ffffff
.loading
  font-size 18px
  text-align center
  padding 50px 0
</style>
