<template>
  <div class="list-card" target="_blank">
    <router-link :to="'/article/'+item.id">
     <div class="thumb">
        <img :src="item.pic+'?imageView2/0/w/250/h/200'" alt="">
      </div>
      <div class="content">
        <div class="category_name">{{item.category_name}}</div>
        <div class="title">{{item.title }}</div>
        <div class="author_name">{{item.author_name}}</div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ListCard',
  props: {
    item: Object
  }
}
</script>

<style lang="stylus" scoped>
.list-card a
  margin 15px 3%
  padding 10px
  background-color #ffffff
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
  display flex
  flex-direction row
  border-radius 10px
  .thumb
    // width 41%
    // margin-right 3%
    // height 100px
    width 150px
    height 100px
    margin-right 15px
    overflow hidden
    border-radius 5px
    display flex
    justify-content center
    align-items center
    img
      width 100%
      border-radius 5px
  .content
    width 56%
    .category_name
      margin-bottom 5px
      display inline-block
      background-color #C53B48
      color #FFFFFF
      font-size 12px
      padding 1px 7px
      border-radius 3px
    .title
      color #202020
      font-size 16px
      font-weight bold
      line-height 22px
      margin-bottom 8px
    .author_name
      color #999999
      font-size 11px
      line-height 16px
</style>
