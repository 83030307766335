<template>
  <div class="article">
    <div class="author">
      <div class="author-img">
      </div>
      <img class="avatar" :src="author.author_avatar" alt="">
      <div class="name">
        {{author.author_name}}
      </div>
    </div>
    <div class="list">
      <div class="list-title">所有文章</div>
      <div v-for="item in listData"
          :key="item.id">
        <ListCard :item="item"/>
      </div>
      <div class="loadmore" @click="getMoreList">{{loadState}}</div>
    </div>
  </div>
</template>

<script>
import ListCard from '@/components/ListCard.vue'
export default {
  name: 'Author',
  components: {
    ListCard
  },
  data () {
    return {
      authorId: '',
      author: {},
      listData: [],
      listLastId: '',
      loadState: '点击加载更多'
    }
  },
  created () {
    const authorId = this.$route.params.id
    this.authorId = authorId
    this.getListData(authorId)
  },
  methods: {
    getMoreList () {
      const authorId = this.authorId
      const lastId = this.listLastId
      var that = this
      if (that.listLastId !== 0) {
        that.$http.get('/article/list?author_id=' + authorId + '&last_id=' + lastId)
          .then(function (res) {
            const list = res.list
            that.listLastId = res.last_id
            if (res.list && res.list.length < 10) {
              that.listData.push(...list)
            } else {
              that.loadState = '没有更多了'
            }
          })
      } else {
        that.loadState = '没有更多了'
      }
    },
    getListData (authorId) {
      var that = this
      that.$http.get('/article/author/' + authorId + '?status=1')
        .then(function (res) {
          that.author = res
          that.listData = res.article_list.list
          that.listLastId = res.article_list.last_id
        })
    }
  },
  metaInfo () {
    return {
      title: this.author.author_name + '的文章列表'
    }
  }
}
</script>

<style lang="stylus" scoped>
.article
  display block
  background-color #F5F5F5
  width 100%
  height 100%
  padding 0px
  .author
    background-color #ffffff
    box-shadow 0px 0px 10px rgba(0, 0, 0, 0.3)
    padding-bottom 18px
    .author-img
      background-color #686969
      height 100px
    .avatar
      display block
      width 90px
      height 90px
      border-radius 50%
      margin -58px auto
    .name
      font-size 18px
      line-height 25px
      color #202020
      font-weight bold
      text-align center
      padding-top 65px
  .list
    .list-title
      padding 15px 0 0 3%
      font-size 18px
      line-height 25px
      color #202020
      font-weight bold
    .loadmore
      padding 10px 25px 20px 25px
      font-size 15px
      text-align center
      color #666666
      background-color #ffffff
</style>
