import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from './config/httpConfig'

Vue.prototype.$http = axios
Vue.config.productionTip = false

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
// router.beforeEach((to, from, next) => {
//   console.log('路由', to.meta)
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
