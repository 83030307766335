<template>
  <div class="category">
    <div class="">
      <template v-if="categoryInfo.content_type == 2">
        <div class="category-audio">
          <img class="bg-img" :src="categoryData.pic" alt="">
          <audio controls="controls"
                  :autoplay="false"
                  ref='audio'
                  :src="currentMediaUrl"
                  controlsList="nodownload">
          </audio>
        </div>
      </template>
      <template v-else-if="categoryInfo.content_type == 3">
        <div class="category-video">
          <video :src="currentMediaUrl"
                  :autoplay="false"
                  :controls="true"
                  controlsList="nodownload"></video>
        </div>
      </template>
      <div class="category-info">
        <div class="title">{{currentTitle}}</div>
        <div class="speaker">
          <img :src="categorySpeaker.avatar" alt="">
          <span class="name">{{categorySpeaker.name}}</span>
        </div>
      </div>
      <div class="">
        <div class="catagory-title">
          <div class="title">目录</div>
          <span class="total">共{{categoryData.content_total}}讲</span>
        </div>
        <div class="catalog">
          <div class="catalog-list"
                :class="(item.id==currentId ? 'active' : '')"
                v-for="(item,index) in categoryList"
                :key="index"
                @click="changeCurrent(item.id,item.title,item.media_url)">
            <div class="num">{{index+1}}</div>
            <div class="content">
              <div class="title">{{item.title}}</div>
              <div class="time">
                <span>时长：{{item.media_duration_str}}</span>
                <span>{{item.create_time_str}}</span>
              </div>
            </div>
          </div>
          <div class="catalog-list-all" @click="getAll()">
            <span>{{allState}}</span>
          </div>
        </div>
      </div>
      <div class="">
        <div class="catagory-title">
          <div class="title">专辑</div>
        </div>
        <div class="list-card" target="_blank">
          <div class="thumb">
              <img :src="categoryData.pic+'?imageView2/0/w/250/h/200'" alt="">
            </div>
            <div class="content">
              <div class="category_name">{{categoryData.group_name}}</div>
              <div class="title">{{categoryData.name }}</div>
              <div class="author_name" v-html="categoryData.intro"></div>
            </div>
        </div>
      </div>
      <div class="download">
        <a href="http://download.jinrifuyin.yimaneili.cc">下载今日福音APP，每日灵修听道</a>
      </div>
    </div>
    <!-- <div class="loading" v-else>
      正在加载中...
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Category',
  data () {
    return {
      categoryData: {}, // 专辑数据
      categoryList: [], // 专辑音频列表
      categoryInfo: {}, // 专辑内容
      categorySpeaker: {}, // 专辑作者
      currentId: '', // 当前播放id
      currentTitle: '', // 当前播放标题
      currentMediaUrl: '', // 当前播放路径
      allState: '展开全部',
      categoryId: '' // 当前专辑id
    }
  },
  created () {
    var that = this
    const currentId = this.$route.params.currentId
    that.categoryId = this.$route.params.id
    that.$http.get('/category/info/?id=' + that.categoryId + '&content_id=0')
      .then(function (res) {
        that.categoryData = res
        that.categoryList = res.content_list
        that.categoryInfo = res.content_info
        that.categorySpeaker = res.speaker
        that.currentId = currentId
        that.currentTitle = res.content_info.title
        that.currentMediaUrl = res.content_info.media_url
      })
  },
  methods: {
    // 点击播放当前音/视频
    changeCurrent (id, title, mediaUrl) {
      this.currentId = id
      this.currentTitle = title
      this.currentMediaUrl = mediaUrl
    },
    // 点击展开全部
    getAll () {
      var that = this
      that.$http.get('/category/info/?id=' + that.categoryId + '&count=100')
        .then(function (res) {
          that.categoryList = res.content_list
          that.allState = '没有更多了'
        })
    }
  },
  metaInfo () {
    return {
      title: this.categoryData.name
    }
  }
}
</script>

<style lang="stylus">
.category
  background-color: #F5F5F5
  padding-bottom: 50px
  .category-audio
    position: relative
    height: 240px
    overflow hidden
    display: flex
    justify-content: center
    align-items: center
    .bg-img {
      position: relative
      width: 100%
      height: auto
    }
    audio
      position absolute
      bottom 5px
      left: 0px
      width: 94%
      margin-left: 3%
      display: block !important
      border-radius: 0px !important
  .category-video
    video
      width: 100%
  .category-info
    background-color: #fff
    padding: 15px
    .title
      color: #202020
      font-size 18px
      line-height: 25px
      font-weight bold
      padding-bottom: 8px
    .speaker
      display: flex
      flex-direction: row
      align-items: center
      img
        width: 40px
        height: 40px
        border-radius: 50%
        position relative
        z-index 1
      .name
        margin-left: -10px
        background-color: #C53B48
        color: #fff
        font-size 12px
        line-height: 20px
        height: 20px
        padding: 0 15px
        border-radius: 3px
  .catagory-title
    padding: 20px
    display: flex
    flex-direction: row
    justify-content: space-between
    align-content: center
    .title
      color: #202020
      font-size 18px
      font-weight bold
      line-height: 25px
    .total
      color: #999999
      font-size 14px
      line-height: 20px
  .catalog
    background-color #ffffff
    .catalog-list
      padding 15px
      display: flex
      flex-direction: row
      align-items: center
      border-bottom 1px solid #E4E4E4
      .num
        color: #999999
        font-size: 16px
        font-weight bold
        line-height: 22px
        width: 34px
      .content
        .title
          color: #202020
          font-size 16px
          line-height: 24px
          padding-bottom: 8px
        .time
          color: #999999
          display: flex
          flex-direction: row
          span
            padding-right: 25px
            font-size 11px
            line-height: 16px
    .catalog-list-all
      padding: 15px 0
      span
        display: block
        text-align: center
    .active
      .num
        color: #C53B48
      .content
        .title
          color: #C53B48
  .list-card
    margin 0 3% 15px 3%
    padding 10px
    background-color #ffffff
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
    display flex
    flex-direction row
    border-radius 10px
    .thumb
      // width 41%
      // margin-right 3%
      // height 100px
      width 150px
      height 100px
      margin-right 15px
      overflow hidden
      border-radius 5px
      display flex
      justify-content center
      align-items center
      img
        width 100%
        border-radius 5px
    .content
      width 56%
      .category_name
        margin-bottom 5px
        display inline-block
        background-color #C53B48
        color #FFFFFF
        font-size 12px
        padding 1px 7px
        border-radius 3px
      .title
        color #202020
        font-size 16px
        font-weight bold
        line-height 22px
        margin-bottom 8px
      .author_name
        color #999999
        font-size 11px
        line-height 16px
  .download
    position fixed
    width 90%
    left: 5%
    bottom 0px
    background-color #C53B48
    text-align center
    height 40px
    line-height 40px
    border-radius 20px
    a
      font-size 16px
      line-height 22px
      color #ffffff
.loading
  font-size 18px
  text-align center
  padding 50px 0
</style>
